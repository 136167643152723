import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './styles.scss';
import {lazyImages} from "./js/lazy-images";
import {accordions} from "./js/accordions";
import {mainMenu} from "./js/main-menu";

mainMenu();
accordions();
lazyImages();



// Get all swiper containers
let swiperContainers = document.querySelectorAll('.swiper-hero-big');

// Initialize each swiper
swiperContainers.forEach((container, index) => {
    new Swiper(container, {
        autoplay: {
            delay: 7000,
            disableOnInteraction: false,
        },
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
});

const mobileTeaserSwiper = document.querySelectorAll('.teaser-box-swiper');
mobileTeaserSwiper.forEach((container, index) => {
    new Swiper(container, {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 16
    });
});




