export function mainMenu() {
    const burgerBtn = document.querySelector('.burger');
    const bodyElem = document.querySelector('.body');
    const mobileMenu = document.querySelector('.mobile-menu');
    const closeMenu = document.querySelector('.close-menu');

    burgerBtn.addEventListener('click', event => {
        burgerBtn.classList.add('show');
        mobileMenu.classList.add('show');
        bodyElem.classList.add('no-scrolling');
    });
    closeMenu.addEventListener('click',() => {
        burgerBtn.classList.remove('show');
        mobileMenu.classList.remove('show');
        bodyElem.classList.remove('no-scrolling');
    });

}

